<template>
  <div class="form">
    <div class="wrapper illustration illustration_percent">
      <Backlink title="detail" service="mortgage" backlink="/mortgage/old-realty-price" />
      <div class="content">
        <div class="content__container">
          <form @submit.prevent>
            <p class="promo promo_blocks">{{ "s2p7_text_1" | localize }}</p>
            <v-radio-group
              v-model="old_mortgage"
              class="row"
              :class="{
                invalid: $v.old_mortgage.$dirty && !$v.old_mortgage.required,
              }"
              ref="old_mortgage"
              row
              @change="
                $v.old_mortgage.$touch();
                checkForm(false);
              "
            >
              <v-radio
                :label="'s2p7_radio_1' | localize"
                value="1"
                on-icon="icon-radio-on"
                off-icon="icon-radio-off"
              ></v-radio>
              <v-radio
                :label="'s2p7_radio_2' | localize"
                value="2"
                on-icon="icon-radio-on"
                off-icon="icon-radio-off"
              ></v-radio>
              <v-radio
                :label="'s2p7_radio_3' | localize"
                value="3"
                on-icon="icon-radio-on"
                off-icon="icon-radio-off"
              ></v-radio>
            </v-radio-group>

            <div v-if="old_mortgage == 1">
              <p class="question">{{ "s2p7_text_2" | localize }}</p>
              <div class="form__group">
                <v-text-field
                  v-model="self_price"
                  class="input_number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  @input="checkForm($v.self_price)"
                  @keypress="onKeyPressHandler"
                  @keyup="
                    onKeyUpPriceHandler('self_price', self_price);
                    countCapital(self_price);
                    countAllCapital(0);
                  "
                  :class="{
                    invalid: $v.self_price.$dirty && !$v.self_price.required,
                  }"
                  :hint="
                    ($v.self_price.$dirty && !$v.self_price.required
                      ? 'form_reqiered'
                      : 'empty') | localize
                  "
                  :placeholder="'s4p9_placeholder_1' | localize"
                  append-icon="icon-₪"
                  ref="self_price"
                  outlined
                ></v-text-field>
              </div>
              <p class="subtext">
                {{ capital_1.toLocaleString() }} <span class="icon-₪"></span>
                {{ "s2p7_subtext_1" | localize }}
              </p>

              <p class="question">{{ "s2p7_text_3" | localize }}</p>
              <div class="form__group">
                <v-text-field
                  v-model="add_capital"
                  class="input_number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  @input="checkForm($v.add_capital)"
                  @keypress="onKeyPressHandler"
                  @keyup="
                    onKeyUpPriceHandler('add_capital', add_capital);
                    countAllCapital(add_capital);
                  "
                  :class="{
                    invalid: $v.add_capital.$dirty && !$v.add_capital.required,
                  }"
                  :hint="
                    ($v.add_capital.$dirty && !$v.add_capital.required
                      ? 'form_reqiered'
                      : 'empty') | localize
                  "
                  :placeholder="'s4p9_placeholder_1' | localize"
                  append-icon="icon-₪"
                  ref="add_capital"
                  outlined
                ></v-text-field>
              </div>
              <p class="subtext">
                {{ capital_3.toLocaleString() }} <span class="icon-₪"></span>
                {{ "s2p7_subtext_2" | localize }}
              </p>
            </div>

            <div v-if="old_mortgage == 2">
              <p class="question">{{ "s2p7_text_6" | localize }}</p>
              <div class="form__group">
                <v-text-field
                  v-model="self_price"
                  class="input_number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  @input="checkForm($v.self_price)"
                  @keypress="onKeyPressHandler"
                  @keyup="onKeyUpPriceHandler('self_price', self_price)"
                  :class="{
                    invalid: $v.self_price.$dirty && !$v.self_price.required,
                  }"
                  :hint="
                    ($v.self_price.$dirty && !$v.self_price.required
                      ? 'form_reqiered'
                      : 'empty') | localize
                  "
                  :placeholder="'s4p9_placeholder_1' | localize"
                  append-icon="icon-₪"
                  ref="self_price"
                  outlined
                ></v-text-field>
              </div>

              <p class="question">{{ "s2p7_text_5" | localize }}</p>
              <div class="form__group">
                <v-text-field
                  v-model="mid_price"
                  class="input_number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  @input="checkForm($v.mid_price)"
                  @keypress="onKeyPressHandler"
                  @keyup="
                    onKeyUpPriceHandler('mid_price', mid_price);
                    countAllCapitalWithoutMortgage(mid_price);
                  "
                  :placeholder="'s4p9_placeholder_1' | localize"
                  append-icon="icon-₪"
                  ref="mid_price"
                  outlined
                ></v-text-field>
              </div>
              <p class="subtext">
                {{ capital_2.toLocaleString() }} <span class="icon-₪"></span>
                {{ "s2p7_subtext_1" | localize }}
              </p>

              <p class="question">{{ "s2p7_text_3" | localize }}</p>
              <div class="form__group">
                <v-text-field
                  v-model="add_capital"
                  class="input_number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  @input="checkForm($v.add_capital)"
                  @keypress="onKeyPressHandler"
                  @keyup="
                    onKeyUpPriceHandler('add_capital', add_capital);
                    countSumCapital(add_capital);
                  "
                  :class="{
                    invalid: $v.add_capital.$dirty && !$v.add_capital.required,
                  }"
                  :hint="
                    ($v.add_capital.$dirty && !$v.add_capital.required
                      ? 'form_reqiered'
                      : 'empty') | localize
                  "
                  :placeholder="'s4p9_placeholder_1' | localize"
                  append-icon="icon-₪"
                  ref="add_capital"
                  outlined
                ></v-text-field>
              </div>
              <p class="subtext">
                {{ capital_3.toLocaleString() }} <span class="icon-₪"></span>
                {{ "s2p7_subtext_2" | localize }}
              </p>
            </div>

            <div v-if="old_mortgage == 3">
              <p class="question">{{ "s2p7_text_4" | localize }}</p>
              <div class="form__group">
                <v-text-field
                  v-model="self_price"
                  class="input_number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  @input="checkForm($v.self_price)"
                  @keypress="onKeyPressHandler"
                  @keyup="onKeyUpPriceHandler('self_price', self_price)"
                  :class="{
                    invalid: $v.self_price.$dirty && !$v.self_price.required,
                  }"
                  :hint="
                    ($v.self_price.$dirty && !$v.self_price.required
                      ? 'form_reqiered'
                      : 'empty') | localize
                  "
                  :placeholder="'s4p9_placeholder_1' | localize"
                  append-icon="icon-₪"
                  ref="self_price"
                  outlined
                ></v-text-field>
              </div>

              <p class="question">{{ "s2p7_text_5" | localize }}</p>
              <div class="form__group">
                <v-text-field
                  v-model="mid_price"
                  class="input_number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  @input="checkForm($v.mid_price)"
                  @keypress="onKeyPressHandler"
                  @keyup="
                    onKeyUpPriceHandler('mid_price', mid_price);
                    countAllCapitalWithMortgage(mid_price);
                  "
                  :placeholder="'s4p9_placeholder_1' | localize"
                  append-icon="icon-₪"
                  ref="mid_price"
                  outlined
                ></v-text-field>
              </div>
              <p class="subtext">
                {{ capital_2.toLocaleString() }} <span class="icon-₪"></span>
                {{ "s2p7_subtext_1" | localize }}
              </p>

              <p class="question">{{ "s2p7_text_3" | localize }}</p>
              <div class="form__group">
                <v-text-field
                  v-model="add_capital"
                  class="input_number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  @input="checkForm($v.add_capital)"
                  @keypress="onKeyPressHandler"
                  @keyup="
                    onKeyUpPriceHandler('add_capital', add_capital);
                    countSumCapital(add_capital);
                  "
                  :class="{
                    invalid: $v.add_capital.$dirty && !$v.add_capital.required,
                  }"
                  :hint="
                    ($v.add_capital.$dirty && !$v.add_capital.required
                      ? 'form_reqiered'
                      : 'empty') | localize
                  "
                  :placeholder="'s4p9_placeholder_1' | localize"
                  append-icon="icon-₪"
                  ref="add_capital"
                  outlined
                ></v-text-field>
              </div>
              <p class="subtext">
                {{ capital_3.toLocaleString() }} <span class="icon-₪"></span>
                {{ "s2p7_subtext_2" | localize }}
              </p>
            </div>

            <button
              type="button"
              class="button button__form"
              :class="{ button__form_disabled: !success }"
              @click="submitHandler"
            >
              {{ "form_continue" | localize }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import VueCookies from 'vue-cookies'
import Backlink from '@/components/base/Backlink.vue'
import { formMixin } from '@/mixins/formMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'
import { numberMixin } from '@/mixins/numberMixin.js'
import { saveMixin } from '@/mixins/saveMixin.js'

const mortgage = VueCookies.get('mortgage') || {}

export default {
  name: 'Mortgage-prices',
  components: { Backlink },
  mixins: [formMixin, commonMixin, numberMixin, saveMixin],
  data: () => ({
    capital_1: 0,
    capital_2: 0,
    capital_3: 0,
    old_mortgage: mortgage.old_mortgage || null,
    self_price: null,
    mid_price: null,
    add_capital: null
  }),
  validations: {
    old_mortgage: { required },
    self_price: { required },
    add_capital: { required }
  },
  methods: {
    countCapital (price) {
      price = parseInt(this.parseNumber(price))
      if (mortgage.old_price && mortgage.old_price > 0) {
        this.capital_1 = price > 0 ? (price < mortgage.old_price ? mortgage.old_price - price : 0) : mortgage.old_price
      }
    },
    countAllCapital (price) {
      price = parseInt(this.parseNumber(price))
      const capital = this.capital_1 > 0 ? parseInt(this.parseNumber(this.capital_1)) : 0
      this.capital_3 = price > 0 ? capital + price : capital
    },
    countSumCapital (price) {
      price = parseInt(this.parseNumber(price))
      const capital = this.capital_2 > 0 ? parseInt(this.parseNumber(this.capital_2)) : 0
      this.capital_3 = price > 0 ? capital + price : capital
    },
    countAllCapitalWithMortgage (price) {
      price = parseInt(this.parseNumber(price))
      const oldMortgage = parseInt(this.parseNumber(this.self_price))
      if (mortgage.old_price && mortgage.old_price > 0) {
        this.capital_2 = mortgage.old_price > price + oldMortgage ? mortgage.old_price - oldMortgage - price : 0
      }
    },
    countAllCapitalWithoutMortgage (price) {
      price = parseInt(this.parseNumber(price))
      const oldPrice = parseInt(this.parseNumber(this.self_price))
      if (mortgage.old_price && mortgage.old_price > 0) {
        this.capital_2 = oldPrice > price ? oldPrice - price : 0
      }
    },
    submitHandler () {
      this.self_price = this.parseNumber(this.self_price)
      this.add_capital = this.parseNumber(this.add_capital)

      if (this.$v.$invalid) {
        this.$v.$touch()
        this.success = false
        return
      }

      const droppedSteps = [
        '/mortgage/sell-during',
        '/mortgage/help',
        '/mortgage/old-realty-price-not-sold',
        '/mortgage/old-mortgage-not-sold-realty',
        '/mortgage/old-mortgage-not-sold-price',
        '/mortgage/self-money'
      ]

      // Сброс следующих шагов линии действий
      this.dropSteps('mortgage', this.$router.currentRoute.path, droppedSteps[0])

      // Добавление пропущенных шагов
      this.addMissedSteps('mortgage', droppedSteps)

      // Сохраняем скорректированную сумму продажи старого имущества
      mortgage.final_capital = this.capital_3
      mortgage.old_mortgage = this.old_mortgage
      this.$cookies.set('mortgage', mortgage)
      this.saveServiceInfo('mortgage', '/mortgage/banks', { additional_capital: this.capital_3 })
    }
  }
}
</script>
